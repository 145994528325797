import { useState, useRef, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment'
import BillNumber from '../../Function/BillNumber';
import ShortText from '../../Function/Text';

const VerifyModal = ({BillData}) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [AllowSend, setAllowSend] = useState(false);
    const navigate = useNavigate();
    const exceptThisSymbols = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
    const [AlertError, setAlertError] = useState({});
    


    const handleInput = (event) => {
        // setValues(prev => ({...prev, [event.target.name]: event.target.value}))
        setAllowSend(true);
    }
    
    const handleSubmit = async (event) => {
        event.preventDefault();
        const CustomerData = {
            CustomerName: event.target.elements.CustomerName.value,
            CustomerContact: event.target.elements.CustomerContact.value,
            CustomerTaxID: event.target.elements.CustomerTaxID.value,
            CustomerAddress: event.target.elements.CustomerAddress.value,
        };
        console.log(CustomerData);
        try {
            const res = await axios.post(`${process.env.REACT_APP_API_SERVER}/ChangeCustomerData/${BillData.bill_reference}`, CustomerData, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "application/json",
                },
            });
                console.log(res);
                handleClose();
                setAlertError('');
        } catch (err) {
            // console.error('Error fetching bill history', err);
            console.log(err.response);
            setAlertError(err.response.data.error);
        }
    }

    useEffect(() => {
    }, [])
    

    return (
        <>
            {
                BillData.st_status == "process" ? (
                    <button className="btn btn-primary col-12" type="button" onClick={handleShow}>
                        <i className="bi bi-pencil" /> รายละเอียดลูกค้า
                    </button>
                ): (
                    <button className="btn btn-primary col-12" type="button" onClick={handleShow}>
                        <i className="bi bi-person-vcard"></i> ดูรายละเอียดลูกค้า
                    </button>
                )
            }
            <Modal centered show={show} onHide={handleClose}>
                <Modal.Header>
                    <Modal.Title>
                        <h5 className="modal-title" id="exampleModalLabel">ข้อมูลลูกค้า</h5>
                    </Modal.Title>
                </Modal.Header>
                <form onSubmit={handleSubmit}>
                    <Modal.Body>
                        <div className="modal-body">
                            <div className="row">
                                {
                                    BillData.st_status == "process" ? (
                                        <>
                                            <div className="col-md-6">
                                                <label htmlFor>ชื่อลูกค้า</label>
                                                <input type="text" onChange={handleInput} className="form-control" name='CustomerName' defaultValue={BillData.bill_name} />
                                                {AlertError.CustomerName && <div className="form-text text-danger">{AlertError.CustomerName}</div>}
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor>หมายเลขโทรศัพท์</label>
                                                <input type="text" onChange={handleInput} className="form-control" name='CustomerContact' defaultValue={BillData.bill_contact} />
                                                {AlertError.CustomerContact && <div className="form-text text-danger">{AlertError.CustomerContact}</div>}
                                            </div>
                                            <div className="col-md-12">
                                                <label htmlFor>เลขประจำตัวผู้เสียภาษี</label>
                                                <input type="text" onChange={handleInput} className="form-control" name='CustomerTaxID' defaultValue={BillData.bill_taxid} />
                                                {AlertError.CustomerTaxID && <div className="form-text text-danger">{AlertError.CustomerTaxID}</div>}
                                            </div>
                                            <div className="col-md-12">
                                                <label htmlFor>ที่อยู่จัดส่งลูกค้า</label>
                                                <textarea className="form-control" onChange={handleInput} name='CustomerAddress' defaultValue={BillData.bill_address} />
                                                {AlertError.CustomerAddress && <div className="form-text text-danger">{AlertError.CustomerAddress}</div>}
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="col-md-6">
                                                <label htmlFor>ชื่อลูกค้า</label>
                                                <input type="text" onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()} className="form-control" disabled={true} defaultValue={BillData.bill_name} />
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor>หมายเลขโทรศัพท์</label>
                                                <input type="text" className="form-control" disabled={true} defaultValue={BillData.bill_contact} />
                                            </div>
                                            <div className="col-md-12">
                                                <label htmlFor>เลขประจำตัวผู้เสียภาษี</label>
                                                <input type="text" className="form-control" disabled={true} defaultValue={BillData.bill_taxid} />
                                            </div>
                                            <div className="col-md-12">
                                                <label htmlFor>ที่อยู่จัดส่งลูกค้า</label>
                                                <textarea name="change_address" disabled={true} className="form-control" defaultValue={BillData.bill_address} />
                                            </div>
                                        </>
                                    )
                                }
                            </div>
                        </div>
                    </Modal.Body>
                    <div className="modal-footer" style={{justifyContent: 'center', display: 'block'}}>
                        {
                            BillData.st_status == "process" ? (
                                <div className="row">
                                    <div className="col-6">
                                        <button onClick={handleClose} type="button" className="btn btn-outline-secondary col-12">ยกเลิก</button>
                                    </div>
                                    <div className="col-6">
                                        <button type="submit" disabled={AllowSend == true ? false : true} className="btn btn-secondary col-12">ยืนยันข้อมูล</button>
                                    </div>
                                </div>
                            ) : (
                                <div className="row">
                                    <div className="col-12">
                                        <button type="button" onClick={handleClose} className="btn btn-secondary col-12">ปิดหน้าต่าง</button>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </form>
            </Modal>

        </>
    );
};

export default VerifyModal;