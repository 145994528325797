import React, {useState, useEffect, useCallback} from 'react'
import NavBar from './NavBar'
import axios from 'axios'
import moment from 'moment'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { LineChart, PieChart } from '@mui/x-charts';


export default function Home() {

  const ShowSwal = () => {
    Swal.fire({
      icon: "warning",
      title: 'สำคัญกรุณาอ่าน !',
      text: 'กรุณาเพิ่มสินค้าในโปรแกรมเวอร์ชั่นนี้เท่านั้น ฐานข้อมูลถูกแยกกันแล้ว',
      showCancelButton: true,
      confirmButtonText: "เพิ่มข้อมูลสินค้าที่นี่",
      cancelButtonText: "เอาไว้ก่อน"
    }).then((result) => {
      if (result.isConfirmed) {
        window.location = '/PosProduct'
      } 
    })
  }

  const [TodayReportData, setTodayReportData] = useState(
    {
      AllData: {
        TodayReport: {
          Bill: {
            AllBill: 0,
            Success: 0,
            Pause: 0,
          },
          Product: {
            All: 0,
            Success: 0,
            Return: 0
          },
          Income: {
            Today: 0
          }
        },
      },
    }
  );

  const [TodayReportOnlyPaymentCompletedData, setTodayReportOnlyPaymentCompletedData] = useState(
    {
      AllData: {
        TodayReport: {
          Bill: {
            AllBill: 0,
            Success: 0,
            Pause: 0,
          },
          Product: {
            All: 0,
            Success: 0,
            Return: 0
          },
          Income: {
            Today: 0
          }
        },
      },
    }
  );

  const [ChartData, setChartData] = useState({
      AllDate: [],
      AllIncome: []
  });

  const [YesterdayReportData, setYesterdayReportData] = useState(
    {
      AllData: {
        TodayReport: {
          Bill: {
            AllBill: 0,
            Success: 0,
            Pause: 0,
          },
          Product: {
            All: 0,
            Success: 0,
            Return: 0
          },
          Income: {
            Today: 0
          }
        },
      },
    }
  );

  const TodayDate = moment().locale('TH').format('YYYY-MM-DD').toString();
  const YesterdayDate = moment().locale('TH').subtract(1, 'day').format('YYYY-MM-DD').toString();

  const TodayReport = async () => {
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_SERVER}/TodayReport`, {
          Date: TodayDate
        }, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      });
      if(res.data.Status == true){
        console.log(res.data);
        setTodayReportData(res.data);
      }
    } catch (err) {
      console.error('Error searching bill history', err);
    }
  };

  const TodayReportOnlyPaymentCompleted = async () => {
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_SERVER}/TodayReportOnlyPaymentCompleted`, {
          Date: TodayDate
        }, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      });
      if(res.data.Status == true){
        console.log(res.data);
        setTodayReportOnlyPaymentCompletedData(res.data);
      }
    } catch (err) {
      console.error('Error searching bill history', err);
    }
  };

  // const YesterdayReport = async () => {
  //   try {
  //     const res = await axios.post(`${process.env.REACT_APP_API_SERVER}/TodayReport`, {
  //         Date: YesterdayDate
  //       }, {
  //       headers: {
  //         "Access-Control-Allow-Origin": "*",
  //         "Content-Type": "application/json",
  //       },
  //     });
  //     if(res.data.Status == true){
  //       console.log(res);
  //       setYesterdayReportData(res.data);
  //     }
  //   } catch (err) {
  //     console.error('Error searching bill history', err);
  //   }
  // };

  const RequestDataPlotChart = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_SERVER}/7DaysIncome`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      });
      if(res.data.Status == true){
        console.log(res.data);
        if(Object.entries(res.data.ChartData.PlotData.AllDate).length > 0){
          var KeyAllDate = Object.keys(res.data.ChartData.PlotData.AllDate);
          var KeyAllIncome = [];
          KeyAllDate.forEach(key => {
            KeyAllIncome.push(res.data.ChartData.PlotData.AllDate[key]);
          });
          setChartData((prevData) => ({
            ...prevData,
            AllDate: KeyAllDate,
            AllIncome: KeyAllIncome
          }));
        }
      }
    } catch (err) {
      console.error('Error searching bill history', err);
    }
  };

  useEffect(() => {
    TodayReport();
    // YesterdayReport();
    TodayReportOnlyPaymentCompleted();
    RequestDataPlotChart();
    // ShowSwal();
  
  }, []);

  const request = useCallback(() => {
    TodayReport();
    // YesterdayReport();
    TodayReportOnlyPaymentCompleted();
    RequestDataPlotChart();
    console.log(ChartData);

  }, [TodayReportData, YesterdayReportData, TodayReportOnlyPaymentCompletedData, ChartData]);
  

  return (
    <>
        <NavBar />
        <div className="bg-res2" style={{
              backgroundImage: `url(${process.env.PUBLIC_URL}/assets/Background/bg.jpeg)`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'top center',
              backgroundAttachment: 'fixed'
            }} >
          <div className='container-fluid vh-100' onClick={request}>
            <div className="row pt-4">
              <div className="col-md-2">
                <div className="card card-body p-4">
                  <h4 className="card-title mb-3">
                    ข้อมูลการขายวันนี้
                  </h4>
                  <div className="row">
                    <div className="btn-group col-12 mb-2">
                      <button type="button" className="btn btn-secondary myinputgroup-span1 col-6">
                          <i className="bi bi-file-earmark"></i>{' '}
                          {/* ใบเสร็จทั้งหมด */}
                          <span className="badge text-bg-light">{TodayReportData.AllData.TodayReport.Bill.AllBill}</span>
                      </button>
                      <button type="button" className="btn btn-secondary myinputgroup-span2 col-6">
                          <i className="bi bi-bag"></i>{' '}
                          {/* สินค้าที่ขายทั้งหมด  */}
                          <span className="badge text-bg-light">{TodayReportData.AllData.TodayReport.Product.All}</span>
                      </button>
                    </div>
                    <div className="btn-group col-12 mb-2">
                      <button type="button" className="btn btn-success myinputgroup-span1 col-6">
                          <i className="bi bi-file-earmark-check"></i>{' '}
                          {/* ใบเสร็จขายสำเร็จ  */}
                          <span className="badge text-bg-light">{TodayReportData.AllData.TodayReport.Bill.Success}</span>
                      </button>
                      <button type="button" className="btn btn-success myinputgroup-span2 col-6">
                          <i className="bi bi-bag-check"></i>{' '}
                          {/* สินค้าขายสำเร็จ  */}
                          <span className="badge text-bg-light">{TodayReportData.AllData.TodayReport.Product.Success}</span>
                      </button>
                    </div>
                    <div className="btn-group col-12 mb-2">
                      <button type="button" className="btn btn-danger myinputgroup-span1 col-6">
                          <i className="bi bi-file-earmark-x"></i>{' '}
                          {/* ใบเสร็จยกเลิกการขาย  */}
                          <span className="badge text-bg-light">{TodayReportData.AllData.TodayReport.Bill.Pause}</span>
                      </button>
                      <button type="button" className="btn btn-danger myinputgroup-span2 col-6">
                          <i className="bi bi-bag-x"></i>{' '}
                          {/* สินค้าที่รับคืน  */}
                          <span className="badge text-bg-light">{TodayReportData.AllData.TodayReport.Product.Return}</span>
                      </button>
                    </div>
                    
                    
                  </div>
                </div>
              </div> 

              <div className="col-md-5">
                <div className="card card-body p-4 mb-4">
                  <h4 className="card-title mb-4">
                    ยอดขายวันนี้ (ยอดรวม)
                  </h4>
                  <div className="frame-price pt-4 card-colorsmk-blink-nothover mt-3">
                    <h1 className="smktitle text-center" style={{fontSize: `3.5rem`}}>
                      {parseFloat(TodayReportData.AllData.TodayReport.Income.Today).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                    </h1>
                  </div>
                </div>
              </div>

              <div className="col-md-5">
                <div className="card card-body p-4 mb-4">
                  <h4 className="card-title mb-4">
                    ยอดขายวันนี้ (ที่ชำระเงินแล้ว)
                  </h4>
                  <div className="frame-price pt-4 card-colorsmk-blink-nothover mt-3">
                    <h1 className="smktitle text-center" style={{fontSize: `3.5rem`}}>
                      {parseFloat(TodayReportOnlyPaymentCompletedData.AllData.TodayReport.Income.Today).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                    </h1>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="row">
              <div className="col-md-7">
                <div className="card card-body p-4">
                  <h4 className="card-title mb-3">
                    ข้อมูลการขายเมื่อวาน
                  </h4>
                  <div className="row">
                    <div className="col-6">
                      <button type="button" className="btn btn-secondary col-12 mb-2">
                          <i className="bi bi-file-earmark"></i>{' '}
                          ใบเสร็จทั้งหมด <span className="badge text-bg-light">{YesterdayReportData.AllData.TodayReport.Bill.AllBill}</span>
                      </button>
                      <button type="button" className="btn btn-success col-12 mb-2">
                          <i className="bi bi-file-earmark-check"></i>{' '}
                          ใบเสร็จขายสำเร็จ <span className="badge text-bg-light">{YesterdayReportData.AllData.TodayReport.Bill.Success}</span>
                      </button>
                      <button type="button" className="btn btn-danger col-12 mb-2">
                          <i className="bi bi-file-earmark-x"></i>{' '}
                          ใบเสร็จยกเลิกการขาย <span className="badge text-bg-light">{YesterdayReportData.AllData.TodayReport.Bill.Pause}</span>
                      </button>
                    </div>

                    <div className="col-6">
                      <button type="button" className="btn btn-secondary col-12 mb-2">
                          <i className="bi bi-bag"></i>{' '}
                          สินค้าที่ขายทั้งหมด <span className="badge text-bg-light">{YesterdayReportData.AllData.TodayReport.Product.All}</span>
                      </button>
                      <button type="button" className="btn btn-success col-12 mb-2">
                          <i className="bi bi-bag-check"></i>{' '}
                          สินค้าขายสำเร็จ <span className="badge text-bg-light">{YesterdayReportData.AllData.TodayReport.Product.Success}</span>
                      </button>
                      <button type="button" className="btn btn-danger col-12 mb-2">
                          <i className="bi bi-bag-x"></i>{' '}
                          สินค้าที่รับคืน <span className="badge text-bg-light">{YesterdayReportData.AllData.TodayReport.Product.Return}</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-5">
                <div className="card card-body p-4 mb-4">
                  <h4 className="card-title mb-4">
                    ยอดขายเมื่อวาน
                  </h4>
                  <div className="frame-price pt-4 card-colorsmk-blink-nothover mt-3">
                    <h1 className="smktitle text-center" style={{fontSize: `3.5rem`}}>
                      {parseFloat(YesterdayReportData.AllData.TodayReport.Income.Today).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                    </h1>
                  </div>
                </div>
              </div>
            </div> */}

            {
              Object.entries(ChartData.AllDate).length > 0 ? (
                <div className="row">
                  <div className="col-md-7">
                    <div className="card card-body">
                      <div className="d-flex justify-content-center">
                        <LineChart 
                          xAxis={[
                            { data: ChartData.AllDate, scaleType: 'point' }
                          ]}
                          series={[
                            {
                              data: ChartData.AllIncome,
                              area: true
                            },
                          ]}
                          width={900}
                          height={300}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-5">
                      <div className="card card-body">
                        <div className="d-flex justify-content-center">
                          <PieChart
                            series={[
                              {
                                data: [
                                  { id: 0, value: TodayReportData.AllData.TodayReport.Income.Today, label: 'Total' },
                                  { id: 1, value: TodayReportOnlyPaymentCompletedData.AllData.TodayReport.Income.Today, label: 'Purchased' },
                                ],
                                innerRadius: 30,
                                outerRadius: 100,
                                paddingAngle: 5,
                                cornerRadius: 5,
                                startAngle: -90,
                                // endAngle: 180,
                                cx: 150,
                                cy: 150,
                                highlightScope: { faded: 'global', highlighted: 'item' },
                                faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
                              },
                            ]}
                            width={400}
                            height={300}
                          />
                        </div>
                      </div>
                    </div>
                </div>
              ) : null
            }

          </div>
        </div>
    </>
  )
}
